/**
OFFICIAL MATERIAL OUTLINED ICONS FROM material.io
 */

blockquote::after,
blockquote::before,
.d-editor-preview div.poll[data-poll-type='multiple'] li[data-poll-option-id]::before,
.expanded-quote div.poll[data-poll-type='multiple'] li[data-poll-option-id]::before,
.d-editor-preview div.poll li[data-poll-option-id]::before,
.expanded-quote div.poll li[data-poll-option-id]::before,
.d-header .btn.sign-up-button::before,
.fa.fa-user-friends::before,
.fa-stream,
.fa-anchor::before,
.fa-angle-double-down::before,
.fa-angle-double-up::before,
.fa-arrow-down::before,
.fa-archive::before,
.fa-arrows-h::before,
.fa-arrow-up::before,
.fa-asterisk::before,
.fa-at::before,
.fa-backward::before,
.fa-ban::before,
.fa-bar-chart-o::before,
.fa-chart-bar::before,
.fa-bars::before,
.fa-exchange-alt::before,
.fa-bed::before,
.fa-bold::before,
.fa-bookmark::before,
.fa-briefcase::before,
.fa-calendar-alt::before,
.fa-calendar::before,
.fa-calendar-plus-o::before,
.fa-caret-down::before,
.fa-caret-right::before,
.fa-caret-up::before,
.fa-certificate::before,
.fa-check::before,
.fa-far-check-square::before,
.fa-check-square::before,
.fa-check-square-o::before,
.fa-chevron-down::before,
.fa-chevron-up::before,
.fa-circle::before,
.fa-circle-o::before,
.fa-far-circle::before,
.fa-fab-windows,
.fa-clipboard::before,
.fa-close::before,
.fa-far-clock::before,
.fa-clock-o::before,
.fa-code::before,
.fa-columns::before,
.fa-copy::before,
.fa-copyright::before,
.fa-cog::before,
.fa-far-comment::before,
.fa-comment::before,
.fa-far-comment-o::before,
.fa-compress::before,
.fa-desktop::before,
.fa-dot-circle-o::before,
.fa-discourse-bell-one::before,
.fa-discourse-bell-exclamation::before,
.fa-discourse-bell-slash::before,
.fa-far-dot-circle::before,
.fa-download::before,
.fa-ellipsis-h::before,
.fa-envelope::before,
.fa-envelope-o::before,
.fa-far-envelope::before,
.fa-far-edit::before,
.fa-far-eye::before,
.fa-exclamation-circle::before,
.fa-exclamation-triangle::before,
.fa-expand::before,
.fa-eye::before,
.fa-eye-slash::before,
.fa-far-eye-slash::before,
.fa-fast-backward::before,
.fa-fast-forward::before,
.fa-file::before,
.fa-file-text-o::before,
.fa-flag::before,
.fa-folder::before,
.fa-forward::before,
.fa-gavel::before,
.fa-gear::before,
.fa-globe::before,
.fa-group::before,
.fa-hand-o-right::before,
.fa-hand-point-right::before,
.fa-header::before,
.fa-heart::before,
.fa-heart-o::before,
.fa-far-heart::before,
.fa-italic::before,
.fa-far-image::before,
.fa-link::before,
.fa-list::before,
.fa-list-alt::before,
.fa-list-ol::before,
.fa-list-ul::before,
.fa-lock::before,
.fa-magic::before,
.fa-mail-forward::before,
.fa-map-marker::before,
.fa-meh-o::before,
.fa-microphone::before,
.fa-microphone-slash::before,
.fa-minus::before,
.fa-mobile::before,
.fa-moon-o::before,
.fa-paint-brush::before,
.fa-pencil-alt::before,
.fa-pencil-square-o::before,
.fa-pencil::before,
.fa-picture-o::before,
.fa-play::before,
.fa-play-circle-o::before,
.fa-plus::before,
.fa-power-off::before,
.fa-question::before,
.fa-question-circle::before,
.fa-quote-right::before,
.fa-quote-left::before,
.fa-random::before,
.fa-refresh::before,
.fa-reply::before,
.fa-rocket::before,
.fa-search::before,
.fa-shield::before,
.fa-sign-out::before,
.fa-sign-out-alt::before,
.fa-smile-o::before,
.fa-square-o::before,
.fa-far-square::before,
.fa-sun-o::before,
.fa-tag::before,
.fa-tasks::before,
.fa-television::before,
.fa-far-thumbs-up::before,
.fa-thumbtack::before,
.fa-thumb-tack::before,
.fa-times::before,
.fa-fab-android::before,
.fa-times-circle::before,
.fa-trash-alt::before,
.fa-far-trash-alt::before,
.fa-far-trash::before,
.fa-trash::before,
.fa-trash-o::before,
.fa-undo::before,
.fa-unlock::before,
.fa-unlock-alt::before,
.fa-upload::before,
.fa-user::before,
.fa-user-plus::before,
.fa-user-times::before,
.fa-users::before,
.fa-wrench::before,
.fa-far-smile::before,
.fa-far-bell::before,
.fa-bell::before,
.mfp-arrow.mfp-arrow-left::before,
.mfp-arrow.mfp-arrow-right::before,
.mfp-close::before,
.mfp-image-holder .mfp-title::before,
.mfp-title a.image-source-link::before,
.pika-next::before,
.pika-prev::before,
#reply-control .grippie::before,
#reply-control .toggle-preview::before,
.meta .expand::before,
.meta .filename::before {
	font-family: 'Material Icons Outlined';
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	letter-spacing: normal;
	text-rendering: optimizeLegibility;
	text-transform: none;
	vertical-align: -0.302198em;
	white-space: nowrap;
	word-wrap: normal;
}


/**
 */


/**
MATERIAL ICONS FROM materialdesignicons.com
 */

.fa-fab-chrome::before,
.fa-shield-alt::before,
.fa-fab-discord::before,
.fa-far-copyright::before,
.fa-fab-facebook::before,
.fa-fab-windows::before,
.fa-user-shield::before {
	font-family: 'Material Design Icons';
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	letter-spacing: normal;
	text-rendering: optimizeLegibility;
	text-transform: none;
	vertical-align: -0.302198em;
	white-space: nowrap;
	word-wrap: normal;
}


/**

 */

.fa-shield-alt::before {
	content: "\F0780"
}

.fa-user-shield::before {
	content: "\F017C"
}

.fa-far-windows::before {
	content: "\F05B3"
}

.fa-fab-facebook::before {
	content: "\F020C";
}

.fa-far-copyright::before {
	content: "\F05E6"
}

.fa-fab-discord::before {
	content: "\F066F"
}

.fa-far-image::before {
	content: "crop_original";
}

.fa-fab-chrome::before {
	content: "\F02AF";
}

.fa-fab-android::before {
	content: "android";
}

.fa-far-edit::before {
	content: "create";
}

.fa-far-eye-slash::before {
	content: "visibility_off";
}

.fa-exchange-alt::before {
	content: "compare_arrows";
}

.fa-far-bell::before {
	content: "notifications_none";
}

.fa-bell::before {
	content: "notifications_none";
}

.fa-thumb-tack::before,
.fa-thumbtack::before {
	font-family: 'Material Design Icons';
}

.fa-anchor {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-anchor::before {
	content: "av_timer";
}

.fa-angle-double-down {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-angle-double-down::before {
	content: "arrow_drop_down";
}

.fa-angle-double-up {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-angle-double-up::before {
	content: "arrow_drop_up";
}

.fa-arrow-down {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-arrow-down::before {
	content: "arrow_downward";
}

.fa-archive {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-archive::before {
	content: "archive";
}

.fa-arrows-h {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-arrows-h::before {
	content: "swap_horiz";
}

.fa-far-smile::before {
	content: "emoji_emotions";
}

.fa-arrow-up {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-arrow-up::before {
	content: "arrow_upward";
}

.fa-asterisk {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-asterisk::before {
	content: "new_releases";
}

.fa-at {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-at::before {
	content: "person_pin";
}

.fa-backward {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-backward::before {
	content: "settings_backup_restore";
}

.fa-ban {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-ban::before {
	content: "not_interested";
}

.fa-bar-chart-o,
.fa-chart-bar {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-bar-chart-o::before,
.fa-chart-bar::before {
	content: "insert_chart";
}

.fa-bars {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-bars::before {
	content: "menu";
}

.fa-bed {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-bed::before {
	content: "airline_seat_flat";
}

.fa-bold {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-bold::before {
	content: "format_bold";
}

.fa-bookmark {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-bookmark::before {
	content: "bookmark_border";
}

.fa-briefcase {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-briefcase::before {
	content: "work";
}

.fa-calendar {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-calendar::before {
	content: "event";
}

.fa-calendar-alt::before {
	content: "event";
}

.fa-calendar-plus-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-calendar-plus-o::before {
	content: "event";
}

.fa-caret-down {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-caret-down::before {
	content: "arrow_drop_down";
}

.fa-caret-right {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-caret-right::before {
	content: "arrow_right";
}

.fa-caret-up {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-caret-up::before {
	content: "arrow_drop_up";
}

.fa-certificate {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-certificate::before {
	content: "brightness_high";
}

.fa-far-check-square::before {
	content: "check_box";
}

.fa-check {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-check::before {
	content: "check";
}

.fa-check-square {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-check-square::before {
	content: "check_box";
}

.fa-check-square-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-check-square-o::before {
	content: "check_box";
}

.fa-chevron-down {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-chevron-down::before {
	content: "keyboard_arrow_down";
}

.fa-chevron-up {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-chevron-up::before {
	content: "keyboard_arrow_up";
}

.fa-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-circle::before {
	content: "stop_circle";
}

.fa-circle-o,
.fa-far-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-circle-o::before,
.fa-far-circle::before {
	content: "radio_button_unchecked";
}

.fa-clipboard {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-clipboard::before {
	content: "content_paste";
}

.fa-close {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-close::before {
	content: "close";
}

.fa-far-thumbs-up::before {
	content: "thumb_up_alt";
}

.fa-clock-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-clock-o::before {
	content: "access_time";
}

.fa-far-clock {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-far-clock::before {
	content: "access_time";
}

.fa-code {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-code::before {
	content: "code";
}

.fa-columns {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-columns::before {
	content: "view_column";
}

.fa-copy {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-copy::before {
	content: "content_copy";
}

.fa-copyright {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-copyright::before {
	content: "copyright";
}

.fa-cog {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-cog::before {
	content: "settings";
}

.fa-far-comment {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-far-comment::before {
	content: "comment";
}

.fa-comment::before {
	content: "comment";
}

.fa-far-comment-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-far-comment-o::before {
	content: "comment";
}

.fa-compress {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-compress::before {
	content: "fullscreen_exit";
}

.fa-desktop {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-desktop::before {
	content: "desktop_mac";
}

.fa-dot-circle-o,
.fa-far-dot-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-dot-circle-o::before,
.fa-far-dot-circle::before {
	content: "radio_button_checked";
}

.fa-download {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-download::before {
	content: "download";
}

.fa-ellipsis-h {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-ellipsis-h::before {
	content: "more_horiz";
}

.fa-envelope {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-envelope::before {
	content: "mail";
}

.fa-envelope-o,
.fa-far-envelope {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-envelope-o::before,
.fa-far-envelope::before {
	content: "mail_outline";
}

.fa-exclamation-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-exclamation-circle::before {
	content: "error";
}

.fa-exclamation-triangle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-exclamation-triangle::before {
	content: "warning";
}

.fa-expand {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-expand::before {
	content: "fullscreen";
}

.fa-eye {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-eye::before {
	content: "visibility";
}

.fa-far-eye {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-far-eye::before {
	content: "visibility";
}

.fa-eye-slash {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-eye-slash::before {
	content: "visibility_off";
}

.fa-fast-backward {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-fast-backward::before {
	content: "skip_previous";
}

.fa-fast-forward {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-fast-forward::before {
	content: "skip_next";
}

.fa-file {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-file::before {
	content: "insert_drive_file";
}

.fa-file-text-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-file-text-o::before {
	content: "description";
}

.fa-flag {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-flag::before {
	content: "flag";
}

.fa-folder {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-folder::before {
	content: "archive";
}

.fa-forward {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-forward::before {
	content: "fast_forward";
}

.fa-gavel {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-gavel::before {
	content: "gavel";
}

.fa-gear {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-gear::before {
	content: "settings";
}

.fa-globe {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-globe::before {
	content: "public";
}

.fa-group {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-group::before {
	content: "group";
}

.fa-hand-o-right,
.fa-hand-point-right {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-hand-o-right::before,
.fa-hand-point-right::before {
	content: "send";
}

.fa-discourse-bell-exclamation::before {
	content: "notification_important";
}

.fa-discourse-bell-one::before {
	content: "notifications_active";
}

.fa-discourse-bell-slash::before {
	content: "notifications_off";
}

.fa-header {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-header::before {
	content: "format_size";
}

.fa-heart {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-heart::before {
	content: "thumb_up_alt";
}

.fa-heart-o,
.fa-far-heart {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-heart-o::before,
.fa-far-heart::before {
	content: "thumb_up_alt";
}

.fa-italic {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-italic::before {
	content: "format_italic";
}

.fa-link {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-link::before {
	content: "link";
}

.fa-list {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-list::before {
	content: "format_list_bulleted";
}

.fa-list-alt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-list-alt::before {
	content: "format_list_bulleted";
}

.fa-list-ol {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-list-ol::before {
	content: "format_list_numbered";
}

.fa-list-ul {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-list-ul::before {
	content: "format_list_bulleted";
}

.fa-lock {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-lock::before {
	content: "lock";
}

.fa-magic {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-magic::before {
	content: "brush";
}

.fa-mail-forward {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-mail-forward::before {
	content: "forward";
}

.fa-map-marker {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-map-marker::before {
	content: "place";
}

.fa-meh-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-meh-o::before {
	content: "sentiment_neutral";
}

.fa-microphone {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-microphone::before {
	content: "mic";
}

.fa-microphone-slash {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-microphone-slash::before {
	content: "mic_off";
}

.fa-minus {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-minus::before {
	content: "remove";
}

.fa-mobile {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-mobile::before {
	content: "phone_iphone";
}

.fa-moon-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-moon-o::before {
	content: "brightness_3";
}

.fa-paint-brush {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-paint-brush::before {
	content: "brush";
}

.fa-pencil-alt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-pencil-alt::before {
	content: "edit";
}

.fa-pencilt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-pencil::before {
	content: "edit";
}

.fa-pencil-square-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-pencil-square-o::before {
	content: "edit";
}

.fa-picture-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-picture-o::before {
	content: "crop_original";
}

.fa-play {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-play::before {
	content: "play_arrow";
}

.fa-play-circle-o::before {
	content: "gif";
}

.fa-plus {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-plus::before {
	content: "add";
}

.fa-power-off {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-power-off::before {
	content: "power_settings_new";
}

.fa-question {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-question::before {
	content: "help_outline";
}

.fa-question-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-question-circle::before {
	content: "help";
}

.fa-quote-right {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-quote-right::before {
	content: "format_quote";
}

.fa-quote-left::before {
	content: "format_quote";
}

.fa-random {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-random::before {
	content: "shuffle";
}

.fa-refresh {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-refresh::before {
	content: "refresh";
}

.fa-reply {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-reply::before {
	content: "reply";
}

.fa-rocket {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-rocket::before {
	content: "flight_takeoff";
}

.fa-search {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-search::before {
	content: "search";
}

.fa-shield {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-shield::before {
	content: "security";
}

.fa-sign-out {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-sign-out::before {
	content: "exit_to_app";
}

.fa-sign-out-alt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-sign-out-alt::before {
	content: "exit_to_app";
}

.fa-smile-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-smile-o::before {
	content: "insert_emoticon";
}

.fa-square-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-square-o::before {
	content: "check_box_outline_blank";
}

.fa-far-square::before {
	content: "check_box_outline_blank";
}

.fa-sun-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-sun-o::before {
	content: "brightness_5";
}

.fa-tag {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-tag::before {
	content: "label";
}

.fa-tasks {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-tasks::before {
	content: "assignment";
}

.fa-television {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-television::before {
	content: "tv";
}

.fa-thumbtack,
.fa-thumb-tack {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-thumbtack::before,
.fa-thumb-tack::before {
	content: "\F0403";
}

.fa-thumbtack.unpinned,
.fa-thumb-tack.unpinned {
	-webkit-transform: none;
	transform: none;
}

.fa-thumbtack.unpinned::before,
.fa-thumb-tack.unpinned::before {
	content: "\F0404";
}

.fa-times {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-times::before {
	content: "close";
}

.fa-times-circle {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-times-circle::before {
	content: "cancel";
}

.fa-far-trash-alt::before {
	content: "delete";
}

.fa-trash {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-trash::before {
	content: "delete";
}

.fa-trash-alt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-trash-alt::before {
	content: "delete";
}

.fa-trash-o {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-trash-o::before {
	content: "delete";
}

.fa-undo {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-undo::before {
	content: "undo";
}

.fa-unlock {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-unlock::before {
	content: "lock_open";
}

.fa-unlock-alt {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-unlock-alt::before {
	content: "lock_open";
}

.fa-upload {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-upload::before {
	content: "attach_file";
}

.fa.fa-user-friends:before {
	content: "people_alt";
}

.fa-user {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-user::before {
	content: "person";
}

.fa-user-plus {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-user-plus::before {
	content: "person_add";
}

.fa-user-times {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-user-times::before {
	content: "person_outline";
}

.fa-users {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-users::before {
	content: "people";
}

.fa-wrench {
	font-size: inherit !important;
	line-height: inherit !important;
}

.fa-wrench::before {
	content: "settings";
}

.fa-stream::before {
	content: "access_time";
}




.read-state i.fa.fa-circle.d-icon.d-icon-circle::before {
    font-size: 1.1em;
}





/**
Change "Like Icon" Outlined to Filled if a Like has been given
 */



i.fa.fa-heart.d-icon.d-icon-d-liked::before {
	font-family: 'Material Icons';
	-webkit-font-feature-settings: 'liga';
	font-feature-settings: 'liga';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: normal;
	font-size: 17px;
	letter-spacing: normal;
	text-rendering: optimizeLegibility;
	text-transform: none;
	vertical-align: -0.302198em;
	white-space: nowrap;
	word-wrap: normal;
    content: "thumb_up";
}
